const toLove = ((Nums) => {
    const numsReversed = Object.keys(Nums).map(x => +x).filter(x => x > 0)
    const getMinDiv = (num) => {
      for (let i = numsReversed.length; i >= 0; i--)
        if (num >= numsReversed[i])
          return numsReversed[i]
    }
    const isDotRegex = /\.(\d+?)0+$/
    const demolish = (num) => {
      if (typeof num !== "number")
        return ""
  
      if (num < 0)
        return `(⑨)*(${demolish(num * -1)})`.replace(/\*\(1\)/g, "")
  
      if (!Number.isInteger(num)) {
        // abs(num) is definitely smaller than 2**51
        // rescale
        const n = num.toFixed(16).match(isDotRegex)[1].length
        return `(${demolish(num * Math.pow(10, n))})/(10)^(${n})`
      }
  
      if (Nums[num])
        return String(num)
  
      const div = getMinDiv(num)
      return (`${div}*(${demolish(Math.floor(num / div))})+` +
        `(${demolish(num % div)})`).replace(/\*\(1\)|\+\(0\)$/g, "")
    }
    return (num) => demolish(num).replace(/\d+|⑨/g, (n) => Nums[n]).replace("^", "**")
  })({
    "⑨": "5+2-0*1-3-1-4",
    0: "5+2-0-1-3+1-4",
    1: "((-5-2)*0+1-3-1)+4",
    2: "(5*2-0-1-3)*1-4",
    3: "((-5-2)*0+1-3)+1+4",
    4: " ((5*2-0)+1-3)*1-4 ",
    5: " (((-5-2)*0-1)+3-1)+4 ",
    6: " (((-5-2)*0-1)+3)*1+4 ",
    7: " (5*2-0-1)+3-1-4 ",
    8: " ((-5-2)*0+1+3)*1+4 ",
    9: " (5*2-0-1)+3+1-4 ",
    10: " ((5*2-0)+1+3)*1-4 ",
    11: " (5*2-0)+1+3+1-4 ",
    12: " (5-2*0)*1*3+1-4 ",
    13: " (5+2-0-1)*3-1-4 ",
    14: " (5+2-0-1)*3*1-4 ",
    15: " (5+2-0-1)*3+1-4 ",
    16: " ((-5-2)*0+1+3)*1*4 ",
    17: " (5+2-0)*1*3*1-4 ",
    18: " (5+2-0)*1*3+1-4 ",
    19: " ((5+2-0)+1)*3-1-4 ",
    20: " (5-2*0)*1*3+1+4 ",
    21: " ((5+2-0-1)*3-1)+4 ",
    22: " (5*2-0-1)*3-1-4 ",
    23: " (5*2-0-1)*3*1-4 ",
    24: " (5*2-0-1)*3+1-4 ",
    25: " (5+2-0)*(1+3)+1-4 ",
    26: " (5+2-0)*1*3+1+4 ",
    27: " (((5+2-0)+1)*3-1)+4 ",
    28: " ((5*2-0)+1)*3-1-4 ",
    29: " ((5*2-0)+1)*3*1-4 ",
    30: " ((5*2-0)+1)*3+1-4 ",
    31: " (5*2-0-1)*3*1+4 ",
    32: " (5*2-0-1)*(3+1)-4 ",
    33: " (5+2-0)*(1+3)+1+4 ",
    34: " (5*2-0)*1*3*1+4 ",
    35: " (5*2-0)*(1+3)-1-4 ",
    36: " (((5*2-0)+1)*3-1)+4 ",
    37: " (5*2-0)*(1+3)+1-4 ",
    38: " ((5*2-0)+1)*3+1+4 ",
    39: " (5+2-0)*(1+3+1)+4 ",
    40: " (5*2-0-1)*(3+1)+4 ",
    41: " 5*((2-0)+1)*3*1-4 ",
    42: " 5*((2-0)+1)*3+1-4 ",
    43: " ((5*2-0)*(1+3)-1)+4 ",
    44: " (5*2-0)*(1+3)*1+4 ",
    45: " (5*2-0)*(1+3)+1+4 ",
    46: " (5*2-0)*(1+3+1)-4 ",
    48: " (5*((2-0)+1)*3-1)+4 ",
    49: " 5*((2-0)+1)*3*1+4 ",
    50: " 5*((2-0)+1)*3+1+4 ",
    51: " (5-2-0)*(1+(3+1)*4) ",
    52: " ((5*2-0-1)+3+1)*4 ",
    53: " 5+((2-0)+1)*(3+1)*4 ",
    54: " (5*2-0)*(1+3+1)+4 ",
    55: " ((5+2-0)+1+3)*(1+4) ",
    56: " 5*((2-0)+1)*(3+1)-4 ",
    60: " ((5*2-0)+1+3+1)*4 ",
    63: " (5*2-0-1)*(3*1+4) ",
    64: " ((5-2*0)*1*3+1)*4 ",
    65: " ((5*2-0)*1+3)*(1+4) ",
    66: " ((5*2-0)+1)*((3-1)+4) ",
    68: " ((5+2-0-1)*3-1)*4 ",
    70: " ((5*2-0)+1+3)*(1+4) ",
    72: " (5+2-0-1)*3*1*4 ",
    75: " (5-2*0)*1*3*(1+4) ",
    76: " ((5+2-0-1)*3+1)*4 ",
    77: " ((5*2-0)+1)*(3*1+4) ",
    80: " ((5+2-0)*1*3-1)*4 ",
    84: " (5+2-0)*1*3*1*4 ",
    85: " (5-2*0)*(1+(3+1)*4) ",
    88: " ((5+2-0)*1*3+1)*4 ",
    90: " (5+2-0-1)*3*(1+4) ",
    91: " (5+2-0)*(1+3*1*4) ",
    92: " (((5+2-0)+1)*3-1)*4 ",
    95: " 5*((2-0)+1+(3+1)*4) ",
    96: " ((5+2-0)+1)*3*1*4 ",
    98: " (5+2)*((0-1)+3*(1+4)) ",
    100: " (((5+2-0)+1)*3+1)*4 ",
    104: " ((5*2-0-1)*3-1)*4 ",
    105: " (5+2-0)*1*3*(1+4) ",
    108: " (5*2-0-1)*3*1*4 ",
    110: " 5*2*((0-1)+3*1*4) ",
    112: " ((5*2-0-1)*3+1)*4 ",
    116: " ((5+2-0)*(1+3)+1)*4 ",
    119: " (5+2-0)*(1+(3+1)*4) ",
    120: " (5*2-0)*1*3*1*4 ",
    124: " ((5*2-0)*1*3+1)*4 ",
    125: " 5*((2-0)*1+3)*(1+4) ",
    128: " (((5*2-0)+1)*3-1)*4 ",
    130: " (5*2-0)*(1+3*1*4) ",
    132: " ((5*2-0)+1)*3*1*4 ",
    135: " (5*2-0-1)*3*(1+4) ",
    136: " (((5*2-0)+1)*3+1)*4 ",
    140: " (5+2-0)*(1+3+1)*4 ",
    144: " (5*2-0-1)*(3+1)*4 ",
    150: " (5*2-0)*1*3*(1+4) ",
    156: " ((5*2-0)*(1+3)-1)*4 ",
    160: " (5*2-0)*(1+3)*1*4 ",
    164: " ((5*2-0)*(1+3)+1)*4 ",
    165: " ((5*2-0)+1)*3*(1+4) ",
    170: " (5*2-0)*(1+(3+1)*4) ",
    176: " (5*((2-0)+1)*3-1)*4 ",
    180: " 5*((2-0)+1)*3*1*4 ",
    184: " (5*((2-0)+1)*3+1)*4 ",
    200: " (5*2-0)*(1+3+1)*4 ",
    225: " 5*((2-0)+1)*3*(1+4) ",
    240: " 5*((2-0)+1)*(3+1)*4 ",
  })


  export {toLove};
